import { Theme, alpha, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      borderRadius: theme.spacing(1.5),
      display: 'flex',
      fontSize: 12,
      gap: theme.spacing(1),
      padding: theme.spacing(1, 1.5),
      width: '100%',
    },
    error: {
      backgroundColor: alpha(theme.palette.status.red, 0.2),
      color: theme.palette.status.red,
    },
    warning: {
      backgroundColor: theme.palette.warning.light,
      color: theme.palette.warning.main,
    },
    icon: {
      height: 16,
      width: 16,
    },
  }),
);

export default useStyles;
