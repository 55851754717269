import DP from 'components/DashPanel';
import OptionsMenu from 'components/OptionsMenu';
import AddLicenseDialog from './AddLicenseDialog/AddLicenseDialog';
import LicenseItem from './ListItem/LicenseItem';
import * as models from 'models';
import CancelLicenseDialog from './CancelLicenseDialog/CancelLicenseDialog';
import ChangeProductDialog from './ChangeProductDialog/ChangeProductDialog';
import ExtendLicenseDialog from './ExtendLicenseDialog/ExtendLicenseDialog';
import useBillingGeneralPanel from './useBillingGeneralPanel';
import { Box } from '@mui/material';
import { EmptyPanelMessage } from 'components/EmptyPanelMessage/EmptyPanelMessage';
import BillingGroupItem from './BillingGroupItem/BillingGroupItem';
import BillingGroupLicensesDialog from './BillingGroupLicensesDialog/BillingGroupLicensesDialog';
import DeleteBillingGroup from './DeleteBillingGroupDialog/DeleteBillingGroupDialog';
import ManageLicenseBillingGroupDialog from './ManageLicenseBillingGroupDialog/ManageLicenseBillingGroupDialog';
import React from 'react';
import BillingGeneralInfoIcon from './BillingGeneralInfoIcon/BillingGeneralInfoIcon';
import ActivateLicenseDialog from './ActivateLicenseDialog/ActivateLicenseDialog';

export interface IBillingGeneralPanelProps {
  accountId: number;
  billingEntities: models.IBillingEntity[];
  loadingBillingEntities: boolean;
  paymentMethods: models.PaymentMethod[];
  refetchLicensesKey?: number;
  refreshNextPayments: VoidFunction;
  getBillingEntities: VoidFunction;
}

const BillingGeneralPanel = ({
  accountId,
  billingEntities,
  paymentMethods,
  loadingBillingEntities,
  refetchLicensesKey,
  refreshNextPayments,
  getBillingEntities,
}: IBillingGeneralPanelProps) => {
  const {
    tabs,
    search,
    isAdmin,
    license,
    licenses,
    showSearch,
    dialogProps,
    billingEntity,
    displayLicenses,
    displayBillingGroups,
    renderLicenses,
    renderBillingGroups,
    renderLoader,
    listHeight,
    canExtendLicenses,
    billingGeneralPanelItems,
    setSearch,
    handleCloseDialog,
    handleProductChange,
    handleActivateLicense,
    getLicensesForAccount,
    handleLicenseActionDialog,
    billingGroupMenu,
    setBillingEntity,
    handleOpenDialogNew,
    handleCancelLicense,
    handleExtendLicense,
  } = useBillingGeneralPanel({
    accountId,
    billingEntities,
    refetchLicensesKey,
    loadingBillingEntities,
    refreshNextPayments,
    getBillingEntities,
  });

  return (
    <>
      <DP>
        <DP.Header>
          <DP.Title light>Billing General</DP.Title>
          {isAdmin ? <OptionsMenu items={billingGeneralPanelItems} /> : <BillingGeneralInfoIcon />}
        </DP.Header>
        <DP.TabBar tabs={tabs} />
        {showSearch && (
          <DP.SearchInput
            value={search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
          />
        )}

        <DP.Body disablePadding>
          {renderLoader && (
            <DP.List>
              <DP.Loading items={3} variant="circleWithTwoLines" />
            </DP.List>
          )}

          {renderLicenses && (
            <DP.List height={listHeight as 'short' | 'normal' | 'tall'}>
              {displayLicenses.length ? (
                displayLicenses.map((license) => (
                  <LicenseItem
                    key={license.id}
                    isAdmin={isAdmin}
                    onLicenseDialogAction={handleLicenseActionDialog}
                    license={license}
                    canExtendLicenses={canExtendLicenses}
                  />
                ))
              ) : (
                <Box display={'flex'} alignContent={'center'} px={2}>
                  <EmptyPanelMessage panelTitle="licenses" />
                </Box>
              )}
            </DP.List>
          )}

          {renderBillingGroups && (
            <DP.List>
              {displayBillingGroups.length ? (
                displayBillingGroups.map((billingGroup) => (
                  <BillingGroupItem
                    key={billingGroup.id}
                    isAdmin={isAdmin}
                    billingEntity={billingGroup}
                    menu={billingGroupMenu(billingGroup)}
                    onShowLicenses={handleOpenDialogNew}
                  />
                ))
              ) : (
                <Box display={'flex'} alignContent={'center'} px={2}>
                  <EmptyPanelMessage panelTitle="billing groups" />
                </Box>
              )}
            </DP.List>
          )}
        </DP.Body>
      </DP>

      <AddLicenseDialog
        {...dialogProps}
        accountId={accountId}
        sendPurchaseOrder={isAdmin}
        onClose={handleCloseDialog}
      />

      <CancelLicenseDialog
        {...dialogProps}
        license={license}
        onCancelLicense={handleCancelLicense}
      />

      <ActivateLicenseDialog
        {...dialogProps}
        license={license}
        onActivateLicense={handleActivateLicense}
      />

      <ChangeProductDialog
        {...dialogProps}
        accountId={accountId}
        license={license}
        onCancel={handleCloseDialog}
        onProductChange={handleProductChange}
      />

      <ExtendLicenseDialog
        {...dialogProps}
        license={license}
        onExtendLicense={handleExtendLicense}
      />

      <BillingGroupLicensesDialog {...dialogProps} billingEntity={billingEntity} />

      <DeleteBillingGroup
        {...dialogProps}
        billingEntity={billingEntity}
        getBillingEntities={getBillingEntities}
      />

      <ManageLicenseBillingGroupDialog
        {...dialogProps}
        accountId={accountId}
        licenses={licenses}
        billingEntity={billingEntity}
        paymentMethods={paymentMethods}
        setBillingEntity={setBillingEntity}
        getBillingEntities={getBillingEntities}
        getLicensesForAccount={getLicensesForAccount}
      />
    </>
  );
};

export default BillingGeneralPanel;
