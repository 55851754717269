import React from 'react';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@material-ui/core';
import useStyles from './styles';
import clsx from 'clsx';

export enum ESupportTextType {
  ERROR = 'error',
  WARNING = 'warning',
}

interface ISupportTextProps {
  label?: string | React.ReactNode;
  type?: ESupportTextType;
  mb?: number;
}

const SupportText = ({ label, mb, type = ESupportTextType.ERROR }: ISupportTextProps) => {
  const classes = useStyles();

  if (!label) return null;

  return (
    <Box mb={mb} className={clsx(classes.root, classes[type])}>
      <FontAwesomeIcon className={classes.icon} icon={faCircleExclamation} />
      {label}
    </Box>
  );
};

export default SupportText;
