import { Typography, Box, CircularProgress } from '@mui/material';
import OutlinedInput from 'components/Input/OutlinedInput/OutlinedInput';
import * as models from 'models';
import Api, { getErrorMsg } from 'api';
import { useStores } from 'containers/App/App';
import Dialog from 'components/Dialog/Dialog';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ELicenseBillingGeneralDialog, ILicenseBillingGeneralDialogProps } from '../models';
import SupportText, { ESupportTextType } from 'components/SupportText/SupportText';

interface IChangeProductDialogProps extends ILicenseBillingGeneralDialogProps {
  license: models.License | null;
  accountId: number;
  onProductChange: VoidFunction;
}

const ChangeProductDialog = ({
  open,
  type,
  title,
  license,
  TransitionProps,
  accountId,
  onCancel,
  onProductChange,
}: IChangeProductDialogProps) => {
  const { toastStore } = useStores();

  const [products, setProducts] = useState<models.Product[]>([]);
  const [billingEntities, setBillingEntities] = useState<models.IBillingEntity[]>([]);

  const [selectedProductId, setSelectedProductId] = useState<number>();
  const [selectedBillingEntityId, setSelectedBillingEntityId] = useState<number>();

  const [changingProduct, setChangingProduct] = useState(false);

  const [loadingProducts, setLoadingProducts] = useState(false);
  const [loadingBillingEntities, setLoadingBillingEntities] = useState(false);

  const isDialogOpen = useMemo(
    (): boolean => open && type === ELicenseBillingGeneralDialog.CHANGE_PRODUCT,
    [open, type],
  );

  const isOldProductFreeAndNewProductNotFree = useMemo((): boolean => {
    const isOldProductFree = license?.product?.price === '$0.00';
    const isNewProductFree = products.find((it) => it.id === selectedProductId)?.price === '$0.00';
    return isOldProductFree && !!selectedProductId && !isNewProductFree;
  }, [license?.product?.price, products, selectedProductId]);

  const isSubmitDisabled = useMemo(
    (): boolean =>
      !license?.id ||
      !selectedProductId ||
      changingProduct ||
      (isOldProductFreeAndNewProductNotFree && !selectedBillingEntityId),
    [
      changingProduct,
      isOldProductFreeAndNewProductNotFree,
      license?.id,
      selectedBillingEntityId,
      selectedProductId,
    ],
  );

  const handleConfirm = useCallback(async () => {
    if (!license || !selectedProductId) return;

    try {
      setChangingProduct(true);
      await Api.billing.ReplaceLicenseProduct(
        license.id,
        selectedProductId,
        selectedBillingEntityId,
      );
      toastStore.success('Product updated successfully');
      onProductChange();
      onCancel();
    } catch (e) {
      toastStore!.error(getErrorMsg(e));
    } finally {
      setChangingProduct(false);
    }
  }, [license, onCancel, onProductChange, selectedBillingEntityId, selectedProductId, toastStore]);

  const handleChangeProductId = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedProductId(e.target.value as unknown as number);
    setSelectedBillingEntityId(undefined);
  }, []);

  const dialogTitle = useMemo(
    () =>
      isOldProductFreeAndNewProductNotFree
        ? 'Update the license product type and select the billing group.'
        : 'Update the license product type.',
    [isOldProductFreeAndNewProductNotFree],
  );

  useEffect(() => {
    if (!license || type !== ELicenseBillingGeneralDialog.CHANGE_PRODUCT) return;

    const fetchProductsAvailableForLicense = async () => {
      try {
        setLoadingProducts(true);
        const { data } = await Api.billing.getProductsAvailableByLicense(license!.id);
        setProducts(data?.data || []);
      } catch (e) {
        toastStore!.error(getErrorMsg(e));
      } finally {
        setLoadingProducts(false);
      }
    };

    const fetchBillingEntities = async () => {
      try {
        setLoadingBillingEntities(true);
        const { data } = await Api.billing.getBillingEntities(accountId);
        setBillingEntities(data?.data || []);
      } catch (e) {
        toastStore.error(getErrorMsg(e));
      } finally {
        setLoadingBillingEntities(false);
      }
    };

    fetchProductsAvailableForLicense();
    fetchBillingEntities();
  }, [license, type, toastStore, setProducts, setSelectedProductId, accountId]);

  return (
    <Dialog
      open={isDialogOpen}
      title={title}
      disabled={isSubmitDisabled}
      loading={changingProduct}
      content={
        <Box>
          {isOldProductFreeAndNewProductNotFree && !billingEntities.length && (
            <SupportText
              mb={2}
              type={ESupportTextType.WARNING}
              label="To change the license type, a billing group must first be created. Currently, there is
            no billing group associated with this account. Please create a billing group with a
            valid payment method to proceed."
            />
          )}

          <Typography variant="body2">{dialogTitle}</Typography>

          <Box mb={1} mt={2}>
            <OutlinedInput
              select
              fullWidth
              required
              label={'Select Product'}
              disabled={loadingProducts || changingProduct}
              InputProps={{
                endAdornment: loadingProducts && <CircularProgress size={27} />,
              }}
              value={selectedProductId || ''}
              onChange={handleChangeProductId}
              options={products.map((p) => ({ label: p.name, value: p.id }))}
            />
          </Box>

          {isOldProductFreeAndNewProductNotFree && (
            <Box mb={1} mt={2}>
              <OutlinedInput
                select
                fullWidth
                required
                label={'Billing Group'}
                disabled={loadingBillingEntities || changingProduct || !billingEntities.length}
                InputProps={{
                  endAdornment: loadingBillingEntities && <CircularProgress size={27} />,
                }}
                value={selectedBillingEntityId || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSelectedBillingEntityId(e.target.value as unknown as number);
                }}
                options={billingEntities.map((p) => ({ label: p.displayName, value: p.id }))}
              />
            </Box>
          )}
        </Box>
      }
      onConfirm={handleConfirm}
      onCancel={onCancel}
      onClose={onCancel}
      TransitionProps={{
        onExited: (node) => {
          TransitionProps?.onExited && TransitionProps.onExited(node);
          setProducts([]);
          setSelectedProductId(undefined);
          setBillingEntities([]);
          setSelectedBillingEntityId(undefined);
        },
      }}
    />
  );
};

export default ChangeProductDialog;
